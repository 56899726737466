import { render, staticRenderFns } from "./SingleMachine.vue?vue&type=template&id=1972cc42&scoped=true&"
import script from "./SingleMachine.vue?vue&type=script&lang=js&"
export * from "./SingleMachine.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1972cc42",
  null
  
)

export default component.exports