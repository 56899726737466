<template>
	<div class="single-machine">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$t("titles.machine")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0" v-if="mayEdit">
				<small-button :text="$t('terms.edit')" @click.native="goTo({name: 'machine-edit',params:{id:routeEntity.id}})" />
			</div>
		</div>
		<spinner v-if="isLoading"/>
		<template v-else>
			<map-with-details :mapLatLng="{lat: parseFloat(routeEntity.lat), lng: parseFloat(routeEntity.lng)}"
				:markers='[{lat: parseFloat(routeEntity.lat), lng: parseFloat(routeEntity.lng)}]'
			>
				<p><strong>{{$t("labels.name")}}</strong><br>{{routeEntity.name}}</p>
				<p><strong>{{$t("labels.createdDate")}}</strong><br>{{routeEntity.createdAt | moment("DD-MM-YYYY")}}</p>
				<p><strong>{{$t("labels.district")}}</strong><br><template v-if="typeof routeEntity.district !== 'undefined' && routeEntity.district !== null">{{routeEntity.district.name}}</template><template v-else>{{$t('sentences.noChosen')}}</template></p>
				<p><strong>{{$t("labels.status")}}</strong><br>{{formatMachineStatus(routeEntity.status)}}</p>
				<p><strong>{{$t("labels.lastService")}}</strong><br>{{routeEntity.lastService}}</p>
				<p v-if="hasGlobalSuperAdmin"><strong>{{$t("labels.company")}}</strong><br><template v-if="typeof routeEntity.company !== 'undefined' && routeEntity.company !== null">{{routeEntity.company.name}}</template><template v-else>{{$t('sentences.noChosen')}}</template></p>
				<p v-if="hasGlobalSuperAdmin"><strong>{{$tc("terms.trailer")}}</strong><br><template v-if="typeof routeEntity.trailer !== 'undefined' && routeEntity.trailer !== null">{{routeEntity.trailer.serialNumber}}</template><template v-else>{{$t('sentences.noChosen')}}</template></p>
			</map-with-details>
			<action-list
				entity="BookingSegment"
				:title="$tc('listHeaders.upcomingBookings',2)"
				:columns="[
					{name:'id',header:'#ID'},
					{name:'date',header:$t('listHeaders.startDate')},
					{name:'startHour',header:$t('listHeaders.fromTo')},
					{name:'pilot',header:$tc('listHeaders.driver',1)},
					{name:'address',header:$t('listHeaders.location')},
					{name:'status',header:$t('listHeaders.status')},
				]"
				:staticParameters="{
					'slot.machine': routeEntity.id,
					'order[date]': 'asc',
					'order[startHour]': 'asc',
					explode: 'user:name,booking:address',
					status_equals: [3,4,5]
				}"
				:item-link="{
					route: 'booking-view',
					parameters: {
						id: 'booking:id'
					}
				}"
				paginated
			>
				<template v-slot:startHour="slotProps">
					{{("0"+slotProps.data.startHour).slice(-2)+" - "+("0"+slotProps.data.endHour).slice(-2)}}
				</template>
				<template v-slot:date="slotProps">
					{{slotProps.data.date | moment("DD-MM-YYYY")}}
				</template>
				<template v-slot:pilot="slotProps">
					<template v-if="slotProps.data.pilot === null">
						{{$t('errors.notFulfilled')}}
					</template>
					<template v-else>
						{{slotProps.data.pilot.name}}
					</template>
				</template>
				<template v-slot:address="slotProps">
					{{slotProps.data.booking.address}}
				</template>
				<template v-slot:status="slotProps">
					{{formatBookingSegmentStatus(slotProps.data.status)}}
				</template>
			</action-list>
			<action-list
				entity="BookingSegment"
				:title="$tc('listHeaders.previousBookings',2)"
				:columns="[
					{name:'id',header:'#ID'},
					{name:'date',header:$t('listHeaders.startDate')},
					{name:'startHour',header:$t('listHeaders.fromTo')},
					{name:'pilot',header:$tc('listHeaders.driver',1)},
					{name:'address',header:$t('listHeaders.location')},
					{name:'status',header:$t('listHeaders.status')},
				]"
				:staticParameters="{
					'slot.machine': routeEntity.id,
					'order[date]': 'desc',
					'order[startHour]': 'desc',
					explode: 'user:name,booking:address',
					status_equals: [6,7]
				}"
				:item-link="{
					route: 'booking-view',
					parameters: {
						id: 'booking:id'
					}
				}"
				paginated
			>
				<template v-slot:startHour="slotProps">
					{{("0"+slotProps.data.startHour).slice(-2)+" - "+("0"+slotProps.data.endHour).slice(-2)}}
				</template>
				<template v-slot:date="slotProps">
					{{slotProps.data.date | moment("DD-MM-YYYY")}}
				</template>
				<template v-slot:pilot="slotProps">
					<template v-if="slotProps.data.pilot === null">
						{{$t('errors.notFulfilled')}}
					</template>
					<template v-else>
						{{slotProps.data.pilot.name}}
					</template>
				</template>
				<template v-slot:address="slotProps">
					{{slotProps.data.booking.address}}
				</template>
				<template v-slot:status="slotProps">
					{{formatBookingSegmentStatus(slotProps.data.status)}}
				</template>
			</action-list>
			<action-list
				entity="LogEntry"
				:title="$t('listHeaders.status')"
				:columns="[
					{name:'id',header:'#ID'},
					{name:'createdAt',header:$t('listHeaders.createdAt')},
					{name:'pilot',header:$tc('listHeaders.driver',1)},
					{name:'status',header:$t('listHeaders.status')},
				]"
				:staticParameters="{
					machine_equals: routeEntity.id,
					'order[createdAt]': 'desc',
					explode: 'user:name',
				}"
			>
				<template v-slot:createdAt="slotProps">
					{{slotProps.data.createdAt | moment("DD-MM-YYYY HH:mm")}}
				</template>
				<template v-slot:pilot="slotProps">
					{{slotProps.data.user !== null ? slotProps.data.user.name : ""}}
				</template>
				<template v-slot:status="slotProps">
					{{$t("listFields."+slotProps.data.entry)}} <a v-if="JSON.parse(slotProps.data.data) !== null" :href='"https://www.google.com/maps/search/?api=1&query="+JSON.parse(slotProps.data.data).lat+","+JSON.parse(slotProps.data.data).lng+""' target="_blank">{{$t("listFields.seeLocation")}}</a> <span v-else> - {{$t("listFields.noLocationData")}}</span>
				</template>
			</action-list>
<!--			@deprecated Hauling system is deprecated since version 2-->
<!--			<action-list
				entity="HaulingAssignment"
				:title="$tc('listHeaders.haulingAssignment',2)"
				:columns="[
				{name:'id',header:$t('listHeaders.id')},
				{name:'date',header:$t('listHeaders.date')},
				{name:'assignment',header:$tc('listHeaders.assignment')},
				{name:'hauler',header:$t('listHeaders.hauler')},
				{name:'status',header:$t('listHeaders.status')},
			]"
				:staticParameters="{
					explode: 'hauler:name',
					'machine':routeEntity.id,
					'order[firstTime]': 'asc',
				}"
				:row-state-filters="{
					completed: {property: 'status', value: [3,4], compare: 'in'}
				}"
				:show-custom-buttons="true"
				firstColTdClasses="short"
			>
				<template v-slot:date="slotProps">
					<template v-if="slotProps.data.type !== 0" >{{slotProps.data.fromTime | moment('DD-MM-YYYY')}}</template>
					<br v-if="slotProps.data.type === 1" />
					<template v-if="slotProps.data.type !== 2">{{slotProps.data.toTime | moment('DD-MM-YYYY')}}</template>
				</template>
				<template v-slot:assignment="slotProps">
					<template v-if="slotProps.data.type !== 0">{{$t('terms.pickup')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.fromAddress,time: $moment(slotProps.data.fromTime).format("HH:mm")})}}</template>
					<br v-if="slotProps.data.type === 1" />
					<template v-if="slotProps.data.type !== 2">{{$t('terms.delivery')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.toAddress,time: $moment(slotProps.data.toTime).format("HH:mm")})}}</template>
				</template>
				<template v-slot:hauler="slotProps">
					{{slotProps.data.hauler.name}}
				</template>
				<template v-slot:status="slotProps">
					{{formatAssignmentStatus(slotProps.data.status)}}
				</template>
			</action-list>-->
		</template>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import isGranted from "@/mixin/isGranted";
	import {mapState} from "vuex";
	import SmallButton from '@/components/buttons/SmallButton.vue';
	import MapWithDetails from '@/components/modules/MapWithDetails.vue';
	import ActionList from '@/components/list/ActionList.vue';
	import DataConversionMethods from '@/mixin/dataConversionMethods.js';
	import permissions from "@/permissions";
	import EntitySelect from "@/components/inputs/EntitySelectInput";

	export default {
		name: "SingleMachine",
		components: {EntitySelect, Spinner, SmallButton, MapWithDetails, ActionList},
		mixins: [isGranted, DataConversionMethods],
		data(){
			return {
				isLoading: true
			}
		},
		computed: {
			...mapState('concrete', {
				machines: 'machines',
				viewingEntity: 'viewingEntity',
			}),
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.machines[this.$route.params.id] !== 'undefined') ? this.machines[this.$route.params.id] : false ;
			},
			mayEdit(){
				if(this.hasGlobalSuperAdmin || this.isGranted(permissions.MACHINE.UPDATE) )
					return true;
				
				return (this.viewingEntity !== false) ? this.isGranted(permissions.MACHINE.UPDATE,this.viewingEntity) : false;
			}
		},
		methods: {
			goTo(route){
				if(typeof route.params.lang === 'undefined')
					route.params.lang = this.$i18n.locale
				this.$router.push(route);
			},
			fetchEntity(){
				if(this.isMissingData())
				{
					this.isLoading = true;
					this.$store.dispatch('concrete/genericEntityRequest',{entity: {'id': this.$route.params.id,'@type':'Machine'},parameters:{explode: 'company:name,trailer:serialNumber,district:name'}})
					.catch(error => {
						console.log(error);
					})
					.then(() => {
						this.isLoading = false;
					},() => {
						this.isLoading = false;
					})
				}
				else
				{
					this.isLoading = false;
				}
			},
			isMissingData()
			{
				if(this.routeEntity === false)
					return true;

				if(typeof this.routeEntity.company !== 'undefined' && this.routeEntity.company !== null && typeof this.routeEntity.company.name === 'undefined')
					return true;

				if(typeof this.routeEntity.trailer !== 'undefined' && this.routeEntity.trailer !== null && typeof this.routeEntity.trailer.serialNumber === 'undefined')
					return true;

				if(this.routeEntity.district !== null && typeof this.routeEntity.district.name === 'undefined')
					return true;

				return false;
			}
		},
		mounted()
		{
			this.fetchEntity();
		},
		watch: {
			$route: {
				deep:true,
				handler: function(){
					this.fetchEntity();
				}
			}
		}
	};
</script>

<style scoped>

</style>
